<template>
  <section>
    <div v-if="roles && roles.length > 0">
      <div v-for="(role, i) in roles" :key="i">
        <div v-if="role.members && role.members.length > 0">
          <span class="f16 font-weight-bold">{{ role.name }}</span>
          <div v-for="(member, index) in role.members" :key="index">
            <v-card
                v-if="member.registered || member.doctorFullName.trim()"
                class="d-flex align-center py-2 my-2 rounded-lg border"
                @click="openDrBottomSheet(member.doctorId)"
                flat
            >

              <v-avatar size="72" class="mx-3">
                <v-img :lazy-src="require('@/assets/images/profile-circle.png')"
                       :src="member.pic != null ? member.pic : require('@/assets/images/profile.jpg')"></v-img>
              </v-avatar>
              <span class="f15 mx-1 grey--text text--darken-4">
                {{ member.doctorFullName }}
              </span>
            </v-card>
            <v-card v-else class="d-flex align-center py-2 my-2 rounded-lg border border-red" flat>
              <v-avatar size="72" class="mx-3 border border-red">
                <v-img :lazy-src="require('@/assets/images/profile-circle.png')"
                       :src="member.pic != null ? member.pic : require('@/assets/images/profile.jpg')"></v-img>
              </v-avatar>
              <span class="f15 mx-1 red--text red--darken-4 ltr">
                {{ member.mobile }}
              </span>
              <span class="f12 mx-1 red--text red--darken-4">
                ({{ $t('Awaiting confirmation') }})
              </span>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <span class="text-center f16 d-block mt-8" v-else>
      {{ $t('No results found') }}
    </span>

    <v-footer
        fixed
        app
        paddless
        color="transparent"
        class="pb-2 pt-0"
        style="z-index: 5000;"
    >
      <v-container fluid>
        <v-btn color="primary" large block class="" @click="$refs.addMemberBottomSheet.open()" rounded>
          {{ $t('Add members') }}
        </v-btn>
      </v-container>
    </v-footer>

    <BshDoctor ref="drBottomSheet" :fromDoctorApp="true"/>

    <vue-bottom-sheet ref="addMemberBottomSheet">
      <section class="d-flex flex-column justify-content-center">
        <v-form ref="form" v-model="valid" lazy-validation>
          <span class="my-1 d-block text-center f15">{{ $t('Add members') }}</span>

          <span class="mb-1 d-block f13">
          {{ $t('Mobile number') }}
          <span class="f11 grey--text">
            ( {{ $t('No Zero') }} )
          </span>
        </span>
          <v-text-field
              outlined
              single-line
              hide-spin-buttons
              class="ltr text-center"
              hide-details
              v-model="mobile"
              :placeholder="$t('Mobile number')"
              type="number"
              :rules="PhoneNumberRules"
              required
          />

          <span class="mt-4 d-block f13">{{ $t('Role') }}</span>
          <v-select
              :menu-props="{'z-index': '5000000', 'content-class': 'f12' }"
              v-model="role"
              :items="roles"
              hide-details
              single-line
              class="f15"
              item-text="name"
              item-value="code"
              :placeholder="$t('Role')"
              outlined
              :rules="[(v) => !!v]"
          >
          </v-select>


          <span class="mb-1 mt-4 d-block f13">{{ $t('Percent cooperation') }}</span>
          <v-text-field
              outlined
              single-line
              hide-spin-buttons
              hide-details
              v-model="fee"
              prefix="%"
              :placeholder="$t('Percent cooperation')"
              type="number"
              :rules="[(v) => !!v]"
              required
          />

          <span class="mt-4 d-block f13">{{ $t('Licensing authority') }}</span>
          <v-radio-group v-model="certifiedBy" mandatory color="primary" column class="w-100 my-1" hide-details>
            <v-radio
                :label="$t('The organization of the psychological')"
                ripple
                value="NEZAM"
                class="mb-1"
            ></v-radio>

            <v-radio
                :label="$t('Welfare organization')"
                value="BEHZISTI"
                ripple
                class="mt-1"
            ></v-radio>
          </v-radio-group>

          <div class="my-4 w-100">
            <v-btn block color="primary" @click="validate()" :loading="submit_loading" :disabled="submit_loading"
                   rounded>{{ $t('Submit') }}
            </v-btn>
          </div>
        </v-form>
      </section>
    </vue-bottom-sheet>

  </section>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.locale("fa", fa);
moment.loadPersian();
export default {
  components: {
    BshDoctor
  },
  data() {
    return {
      submit_loading: false,
      valid: false,
      mobileNumberPrefix: "+98",

      certifiedBy: "NEZAM",
      fee: '',
      mobile: '',
      PhoneNumberRules: [
        (v) => (v && v.length >= 10) || this.$t('Mobile number must be at least 10 characters'),
        // (v) => (v && v.length <= 11) || this.$t('Mobile number must be at less than 11 characters'),
      ],
      role: null,

      clinicId: null,

      notFindShow: false,
      roles: [],
    };
  },
  methods: {
    showNumber() {
      console.log(this.mobileNumberPrefix + this.mobile)
    },
    openDrBottomSheet(id) {
      this.$refs.drBottomSheet.openWithDoctorId(id);
    },
    getDoctorRoles(page, size) {
      apiService.getDoctorRoles(page, size)
          .then((response) => {
            this.getClinicMemberWithRole(response.data.content, this.clinicId);
          })
          .catch((err) => {
            console.log(err)
            this.$router.go(-1);
          })
    },
    getClinicMemberWithRole(roles, clinicId) {
      apiService.getClinicMemberWithRole(clinicId)
          .then((response) => {
            this.fillData(roles, response.data);
          })
          .catch((err) => {
            console.log(err)
            this.$router.go(-1);
          })
    },
    fillData(roles, members) {
      roles.forEach(role => {
        role.members = [];
        members.forEach(member => {
          if (member.roleCode == role.code) {
            role.members.push(member);
          }
        });
      });

      this.roles = [];
      roles.forEach(el => {
        if (el.code == '4') {
          this.roles.unshift(el);
        } else {
          this.roles.push(el);
        }
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (!this.mobile) {
          this.$toast.error(this.$t("Please enter your mobile number"), {rtl: this.$vuetify.rtl});
        } else if (this.mobile.length < 10) {
          this.$toast.error(this.$t("Mobile number must be at least 10 characters"), {rtl: this.$vuetify.rtl});
        } else if (this.mobile.length > 11) {
          this.$toast.error(this.$t("Mobile number must be at less than 11 characters"), {rtl: this.$vuetify.rtl});
        } else {
          if (this.mobile.startsWith("0")) {
            this.mobile = this.mobile.slice(1, this.mobile.length);
          }
          this.submitAddMember();
        }
      }
    },
    submitAddMember() {
      var ClinicMemberDTO = {
        clinicId: parseInt(this.clinicId),
        fee: parseInt(this.fee),
        mobile: this.mobileNumberPrefix + this.mobile,
        roleCode: this.role,
        certifiedBy: this.certifiedBy
      };
      apiService.addClinicMember(ClinicMemberDTO)
          .then(() => {
            this.submit_loading = false;
            this.$refs.form.reset()
            this.$refs.addMemberBottomSheet.close()
            this.$swal({
              icon: "success",
              text: this.$t('The new member has been successfully added to the clinic'),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.getDoctorRoles(0, 999);
                  }
                });
          })
          .catch((err) => {
            this.submit_loading = false;
            this.$refs.addMemberBottomSheet.close()
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

  },
  created() {
    if (this.$route.params.clinicId) {
      this.clinicId = this.$route.params.clinicId;
    } else {
      this.$router.go(-1);
    }

    this.getDoctorRoles(0, 999);
  }
};
</script>